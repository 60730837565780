<template>
  <div class="flex flex-col neighborhood-detail">
    <div
      class="flex w-full min-h-50 max-h-screen/2"
      v-if="neighborhood && neighborhood.attributes"
    >
      <img class="object-cover w-full h-max" :src="imageUrl" />
    </div>
    <div
      class="min-h-50 px-4 lg:px-8 lg:w-screen-lg max-w-screen-lg mx-auto text-left space-y-6 mt-10"
      v-if="neighborhood && neighborhood.attributes"
    >
      <p class="title text-np-56 lg:text-np-3xl text-np-glimpse">
        {{ neighborhood.attributes.title }}
      </p>
      <content-body :source="neighborhood.attributes.body.content" />
    </div>
    <gallery-slide
      v-if="galleries && galleries.length > 0"
      :images="galleries"
      class="my-18 lg:my-20"
    />
    <share-items />
    <special-item
      v-if="neighborhood && neighborhood.attributes"
      title-list="other neighborhoods"
      type="neighborhood"
      :subType="neighborhood.attributes.type.data.id"
      :exclude-id="neighborhood.id"
    />
  </div>
</template>

<script>
import { getNeighborhoodBySlug } from "@/api/endpoints/neighborhoods";
import to from "await-to-js";
import SpecialItem from "@/components/SpecialItem.vue";
import ShareItems from "@/components/ShareItems.vue";
import ContentBody from "../../components/ContentBody.vue";
import GallerySlide from "../../components/gallery/GallerySlide.vue";
import { getImageUrl } from "@/utils/imageUtils.js";

export default {
  name: "NeighborhoodDetail",
  data() {
    return {
      lang: "en",
      neighborhood_id: 0,
      neighborhood: {},
      galleries: [],
    };
  },
  components: {
    SpecialItem,
    ShareItems,
    ContentBody,
    GallerySlide,
  },
  async mounted() {
    let neighborhood_id = this.$route.params.slug;
    this.neighborhood_id = neighborhood_id;
    await this.setNeighborhoodDetail(this.lang, neighborhood_id);
  },
  watch: {
    "$route.params.slug": {
      handler: async function (neighborhood_id) {
        await this.setNeighborhoodDetail(this.lang, neighborhood_id);
        window.scrollTo({ top: 0, behavior: "smooth" });
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    imageUrl() {
      return this.neighborhood.attributes.image.data.attributes.url;
      // if (this.neighborhood.attributes.image.data.attributes.formats) {
      //   if (
      //     this.neighborhood.attributes.image.data.attributes.formats.large &&
      //     this.neighborhood.attributes.image.data.attributes.formats.large.url
      //   )
      //     return this.neighborhood.attributes.image.data.attributes.formats.large.url;
      //   if (
      //     this.neighborhood.attributes.image.data.attributes.formats.small &&
      //     this.neighborhood.attributes.image.data.attributes.formats.small.url
      //   )
      //     return this.neighborhood.attributes.image.data.attributes.formats.small.url;
      //   if (
      //     this.neighborhood.attributes.image.data.attributes.formats.thumbnail &&
      //     this.neighborhood.attributes.image.data.attributes.formats.thumbnail.url
      //   )
      //     return this.neighborhood.attributes.image.data.attributes.formats.thumbnail
      //       .url;
      // }
      // return "";
    },
  },
  methods: {
    async setNeighborhoodDetail(lang, neighborhoodId) {
      const [err, response] = await to(
        getNeighborhoodBySlug(lang, neighborhoodId)
      );

      if (err) {
        return { errorMessage: err?.response?.data?.error || err.message };
      }
      this.neighborhood = response.data.data[0];
      if (this.neighborhood.attributes.body.images) {
        this.galleries = this.neighborhood.attributes.body.images.data.map(
          (img) => {
            return {
              id: img.id,
              image_url: getImageUrl(img),
            };
          }
        );
      } else {
        this.galleries = [];
      }
    },
  },
};
</script>

<style scoped>
.title {
  font-family: "Saol Text" !important;
  font-style: normal;
}

.menu {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
  color: #5c623f;
}

#experience-content {
  border-top: 1px solid #cecdc8;
}

.discover-more {
  background: #5c623f;
  color: white;
}

.description {
  font-style: normal;
  font-weight: 300;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
